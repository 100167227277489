<template>
  <div>
    <H3>TrendyolHome</H3>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'TrendyolHome',
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  created() {
    this.$store.dispatch('company/getUserCompanies', this.userData.user_uuid)
  },
}
</script>

<style scoped>

</style>
